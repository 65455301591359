import { Link } from "react-scroll";

export default function About() {
    return(
        <section id="about">
            <div className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center">
                <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
                    <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
                        Hi, I'm Yezen.
                    </h1>
                    <p className="mb-8 leading-relaxed">
                        I’m a software developer with experience in web development and building mobile apps. I love  to
                        work on projects that involve tackling new challenges and working with new technologies.
                    </p>
                    <div className="flex justify-center">
                        <Link
                            to="projects"
                            smooth={true}
                            offset={-70}
                            className="inline-flex text-white bg-green-500 border-0 px-6 py-2 focus:outline-none hover:bg-green-600 rounded text-lg cursor-pointer">
                            See my work
                        </Link>
                        {/*<Link*/}
                        {/*    to="contact"*/}
                        {/*    smooth={true}*/}
                        {/*    className="ml-4 inline-flex text-gray-400 bg-gray-800 border-0 px-6 py-2 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg cursor-pointer">*/}
                        {/*    Contact me*/}
                        {/*</Link>*/}
                    </div>
                </div>
            </div>
        </section>
    );
}