import { CheckBadgeIcon, CpuChipIcon } from "@heroicons/react/24/solid";
import { skills } from "../data";

export default function Skills() {
    return(
        <section id="skills">
            <div className="container px-5 py-10 mx-auto">
                <div className="text-center mb-20">
                    <CpuChipIcon className="w-10 inline-block mb-4" />
                    <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
                        Skills & Technologies
                    </h1>
                </div>
                <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                    {skills.map(skill => (
                        <div key={skill} className="p-2 sm:w-1/2 w-full ">
                            <div className="bg-gray-800 rounded flex p-4 h-full items-center hover:border-indigo-500 hover:ring-2 hover:ring-indigo-900 transistion-colors duration-300 ease-in-out">
                                <CheckBadgeIcon className="text-green-400 w-6 h-6 flex-shrink-0 mr-4 "></CheckBadgeIcon>
                                <span className="title-font font-medium text-white ">
                                    {skill}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}