export const projects = [
    {
        title: "Spotify Music Discovery",
        subtitle: "Python (Flask), Spotify API, and Genius API",
        description:
            "A music discovery app to recommend you new songs. Gives a quick preview of the song and a link to the lyrics. Refresh for a new song!",
        link: "https://github.com/NJIT-CS490-SP21/project1-ya82",
    },
    {
        title: "Online Tic-Tac-Toe",
        subtitle: "React, Python (Flask), Socket.IO",
        description:
            "A multiplayer tic-tac-toe game utilizing React frontend clients and a Python backend server. Uses PostgreSQL to keep and update the leaderboard.",
        link: "https://github.com/NJIT-CS490-SP21/project2-ya82",
    },
    {
        title: "Twitter Client",
        subtitle: "Java, Android Studio, and Twitter API",
        description:
            "An Android app that acts as a custom Twitter client. User's can login to their Twitter accounts, view their feed, and post new Tweets to Twitter.",
        link: "https://github.com/yarmout/SimpleTweet",
    },
    {
        title: "Instagram Clone",
        subtitle: "Java, Android Studio, and Parse API",
        description:
            "An Android app that emulates the functionality of Instagram. User's can upload and share photos to the photo feeds of their friends.",
        link: "https://github.com/yarmout/Parstagram",
    },
];

export const skills = [
    "React",
    "Python",
    "Java",
    "C/C++",
    "JavaScript",
    "SQL"
]